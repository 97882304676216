window.Cookies = require("js-cookie");

window.isAdmin = function fnAdm() {
    return window.location.href.indexOf("admin") !== -1;
};

window.ArInput = function fn1($field) {
    // Arabic characters fall in the Unicode range 0600 - 06FF
    var arabicCharUnicodeRange = /[\u0600-\u06FF]/;

    $field.bind("keypress", function (event) {
        var key = event.which;
        // 0 = numpad
        // 8 = backspace
        // 32 = space
        if (key == 8 || key == 0 || key === 32) {
            return true;
        }

        var str = String.fromCharCode(key);
        if (arabicCharUnicodeRange.test(str)) {
            return true;
        }

        return false;
    });
};

window.PhoneInput = function fn2($field) {
    $field.bind("keypress", function (event) {
        var val = $field.val();
        var valLength = val.length;
        if (valLength > 7) {
            return false;
        }
        var keyCode = event.which ? event.which : event.keyCode;
        return !(keyCode > 31 && (keyCode < 48 || keyCode > 57));
    });
};

window.cinInput = function fn3($field) {
    $field.bind("keypress", function (event) {
        var val = $field.val();
        var valLength = val.length;
        if (valLength > 7) {
            return false;
        }
        var keyCode = event.which ? event.which : event.keyCode;
        return !(keyCode > 31 && (keyCode < 48 || keyCode > 57));
    });
    $field.on("copy paste cut", function (e) {
        e.preventDefault(); //disable cut,copy,paste
        return false;
    });
};

window.copyToClipboard = function fn4(text) {
    // Check if the Clipboard API is supported
    if (typeof navigator.clipboard !== "undefined") {
        // Use the writeText() method to copy the text to the clipboard
        navigator.clipboard.writeText(text).then(
            function () {
                // Success!
                console.log("تم نسخ النص إلى الحافظة");
                let copyToast = new Noty({
                    dismissQueue: true,
                    timeout: 1000,
                    progressBar: true,
                    text: "تم نسخ النص إلى الحافظة",
                    type: "success",
                    theme: "metroui",
                    layout: "bottomRight",
                    buttons: false
                });
                copyToast.show();
            },
            function (error) {
                // Error!
                console.log("حدث خطأ أثناء نسخ النص إلى الحافظة : " + error);
                let copyToast = new Noty({
                    dismissQueue: true,
                    timeout: 1500,
                    progressBar: true,
                    text: "حدث خطأ أثناء نسخ النص إلى الحافظة",
                    type: "warning",
                    theme: "metroui",
                    layout: "bottomRight",
                    buttons: false
                });
                copyToast.show();
            }
        );
    } else {
        // Clipboard API is not supported
        console.log("Clipboard API is not supported");
        let copyToast = new Noty({
            dismissQueue: true,
            timeout: 1000,
            progressBar: true,
            text: "متصفحك لا يدعم النسخ إلى الحافظة",
            type: "error",
            theme: "metroui",
            layout: "bottomRight",
            buttons: false
        });
        copyToast.show();
    }
};

window.onload = function () {
    var d = new Date();
    var n = d.getFullYear();
    var cssRule =
        "color: #FF0000;" +
        "font-size: 30px;" +
        "font-weight: bold;" +
        "text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);";
    console.log(
        "%cL'Office National de la Protection Civile, Tous droits réservés © " +
        n,
        cssRule
    );
    var cssRule2 =
        "color: #0d6efd;" +
        "opacity: 0.5;" +
        "font-size: 15px;" +
        "text-decoration: underline;" +
        "font-weight: bold;";
    console.log(
        "%cIl s’agit d’une fonctionnalité de navigateur conçue pour les développeurs.",
        cssRule2
    );

    /*document.addEventListener(
        "contextmenu",
        function (e) {
            e.preventDefault();
        },
        false
    );
    document.addEventListener(
        "keydown",
        function (e) {
            //document.onkeydown = function(e) {
            // "I" key
            if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
                disabledEvent(e);
            }
            // "J" key
            if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
                disabledEvent(e);
            }
            // "S" key + macOS
            if (
                e.keyCode == 83 &&
                (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
            ) {
                disabledEvent(e);
            }
            // "U" key
            if (e.ctrlKey && e.keyCode == 85) {
                disabledEvent(e);
            }
            // "F12" key
            if (event.keyCode == 123) {
                disabledEvent(e);
            }
        },
        false
    );*/
    function disabledEvent(e) {
        if (e.stopPropagation) {
            e.stopPropagation();
        } else if (window.event) {
            window.event.cancelBubble = true;
        }
        e.preventDefault();
        return false;
    }
};


